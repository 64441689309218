<template>
    <BaseScene 
        name="piano" 
        :background="ResourceManager.Get('scenes.piano')"
        @leave="OnLeaveScene" 
        @enter="OnEnterScene"         
    >

        <!-- start -->
        <div class="keys">
            <div class="key" :id="index" v-for="(key, index) in keys" :key="index" :class="key.type">
                <img :src="ResourceManager.Get('piano.key.' + key.type)" @click="playKeySound(index)">
                <template v-if="hasPauseButton(index)">
                    <font-awesome-icon class="play-icon" icon="pause" size="2x" v-if="activeLaughsongIndex == index"/>
                    <font-awesome-icon class="play-icon" icon="play" size="2x" v-else/>
                </template>

                <img class="black" :src="ResourceManager.Get('piano.key.black')" v-if="key.hasBlack" @click="playKeySound(index + '-b')">
                <template v-if="hasPauseButton(index + '-b')">
                    <font-awesome-icon class="play-icon b" icon="pause" size="2x" v-if="activeLaughsongIndex == index + '-b'"/>
                    <font-awesome-icon class="play-icon b" icon="play" size="2x" v-else/>
                </template>
            </div>
        </div>

    </BaseScene>
</template>

<script>
import BaseScene from '../BaseScene.vue'
import SceneTrigger from '../triggers/SceneTrigger.vue';
import { Howl } from 'howler';
import ResourceManager from "@/classes/resource-manager";

export default {
    components: {
        BaseScene
    },
    mounted() {
        let lastWasTripleBlackSet = true;

        for (let i = 0; i < this.numKeys; i++) {
            let key = {
                type: 'white-m',
                hasBlack: false
            };

            if (i == 0 || i == this.numKeys - 1) {
                if (i == 0) {
                    key.type = 'white-l';
                    key.hasBlack = true;
                } else {
                    key.type = 'white-r';
                }
            } else {
                const lastKey = this.keys[i - 1];
                const lastLastKey = this.keys[i - 2];

                switch (lastKey.type) {
                    case 'white-l':
                        key.type = 'white-m';
                        key.hasBlack = true;               
                        break;
                    case 'white-m':
                        if (lastWasTripleBlackSet) {
                            key.type = 'white-r';
                            lastWasTripleBlackSet = !lastWasTripleBlackSet;
                        } else {
                            if (lastLastKey?.type == 'white-m') {
                                key.type = 'white-r';
                                lastWasTripleBlackSet = !lastWasTripleBlackSet;
                            } else {
                                key.type == 'white-m';
                                key.hasBlack = true;
                            }
                        }

                        break;
                    case 'white-r':
                        key.type = 'white-l';
                        key.hasBlack = true;
                        break;
                }
            }

            this.keys.push(key);
        }
    },
    data() {
        return {
            ResourceManager,
            bgm: null,
            bgmShutdown: null,
            numKeys: 17,
            keys: [],
            sounds: {
                '0': 'laughs.andre.1',
                '0-b': 'laughs.andre.2',
                '1': 'laughs.andre.3',
                '1-b': 'laughs.arek.1',
                '2': 'laughs.arek.2',
                '2-b': 'laughs.torsten.1',
                '3': 'laughs.torsten.2',
                '3-b': 'laughs.torsten.3',
                '4': 'laughs.mike.1',
                '4-b': 'laughs.mike.2',
                '5': 'laughs.mike.3',
                '5-b': 'laughsongs.arek',
                '6': 'laughs.mike.4',
                '6-b': 'laughs.rodrigo.1',
                '7': 'laughs.rodrigo.2',
                '7-b': 'laughsongs.torsten',
                '8': 'laughsongs.instrumental',
                '8-b': 'laughsongs.rodrigo',
                '9': 'laughs.rodrigo.3',
                '9-b': 'laughs.rodrigo.4',
                '10': 'laughs.grit.1',
                '10-b': 'laughsongs.nicole',
                '11': 'laughs.grit.2',
                '11-b': 'laughs.grit.3',
                '12': 'laughs.gundula.1',
                '12-b': 'laughs.gundula.2',
                '13': 'laughs.gundula.3',
                '13-b': 'laughs.gundula.4',
                '14': 'laughs.gundula.5',
                '14-b': 'laughs.nicole.1',
                '15': 'laughs.nicole.2',
                '15-b': 'laughs.nicole.3',
                '16': 'laughs.nicole.4',
                '16-b': 'laughs.nicole.5',
            },
            activeLaughsong: null,
            activeLaughsongIndex: null
        }
    },
    methods: {
        playKeySound(index) {
            const name = this.sounds[index];

            const isLaughsong = name.indexOf('laughsongs') != -1;

            const sound = new Howl({
                src: [ResourceManager.Get(name)],
                volume: isLaughsong ? 0.5 : 1,
                loop: isLaughsong,
                html5: true
            });

            if (this.hasPauseButton(index)) {
                this.stopActiveLaughsong();

                if (this.activeLaughsongIndex == index) {
                    this.activeLaughsong = null;
                    this.activeLaughsongIndex = null;
                    return;
                } else {
                    this.activeLaughsong = sound;
                    this.activeLaughsongIndex = index;
                }
            }

            sound.play();
        },
        hasPauseButton(index) {
            return [
                '5-b',
                '7-b',
                8,
                '8-b',
                '10-b'
            ].indexOf(index) != -1;
        },
        stopActiveLaughsong() {
            // if (this.activeLaughsong != null && this.activeLaughsong.playing()) {
            if (this.activeLaughsong != null) {
                this.activeLaughsong.stop();
            }
        },
        OnEnterScene() {
            if (this.bgmShutdown) {
                clearTimeout(this.bgmShutdown);
                this.bgmShutdown = null;
            }

            if (!this.bgm) {
                this.bgm = new Howl({
                    src: [ResourceManager.Get('ambience.stagehall')],
                    loop: true,
                    volume: 1
                });
            }
    
            if (!this.bgm.playing()) {
                // this.bgm.play();
            }
        },
        OnLeaveScene() {
            this.bgm.fade(1.0, 0.0, 2000);
            this.bgmShutdown = setTimeout(() => {
                this.bgm.stop();
                this.bgm.volume(1);
            }, 3000);

            this.stopActiveLaughsong();
        }
    }    
}
</script>

<style lang="scss">
.scene-piano {
    .keys {
        //display: flex;
        position: fixed;
        width: 100%;
        bottom: 1%;

        .key {
            //flex: 0 1 calc(100vw / 17);
            display: inline-block;
            width: calc(100vw / 17);            
            position: relative;
            user-select: none;
            transform: scale(1);
            pointer-events: none;

            img {
                cursor: pointer;
                transition: .35s;
                pointer-events: all;
                position: relative;
                z-index: 1;

                &:hover {
                    transform: scale(0.95);
                }
            }

            img:not(.black) {
                @include height-ar(24.6);
            }


            .black {
                position: absolute;
                @include width-ar(3);
                @include height-ar(16.9275);
                @include xy-ar(0, 6.2);
                transform: translate(-50%, -50%) scale(1);
                z-index: 2;

                &:hover {
                    transform: translate(-50%, -50%) scale(0.95);
                }
            }

            img {
                width: 100%;
            }

            .play-icon {
                @include width-ar(1.5);
                @include xy-ar(0, 5);                
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 1;
                color: #333;
                z-index: 3;
                // margin-left: -15px;
                transform: translate(-50%, -50%);

                &.b {
                    top: -20px;
                    left: 100%;
                    color: #ddd;
                    @include xy-ar(0, 2);
                }
            }
        }
    }
}
</style>